<template>
	<table>
		<tr>
			<td
				:colspan="treeData.children ? treeData.children.length * 2 : 1"
				:class="{
					parentLevel: treeData.children && checkChildrenFilters(treeData.children),
					'parent-level-user': treeData.type == 'user' && treeData.children && treeData.children[0].type == 'user',
					extend: treeData.children && treeData.children.length > 0 && treeData.extend && checkChildrenFilters(treeData.children),
				}"
			>
				<div :class="{ node: true, compact: densityType == 'compact', detailed: densityType == 'detailed' }">
					<!-- PARENT NODE -->
					<div
						class="box-tbf compay-box"
						:class="{ 'compact-department': densityType == 'compact', 'detailed-department': densityType == 'detailed' }"
						@click="$emit('click-node', treeData)"
						v-if="treeData.type == 'company'"
					>
						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{ $t("tree-chart.company") }}
								</div>
								<div class="box-actions">
									<v-popover
										class="counter"
										:disabled="$resize && $mq.below(780)"
										offset="2"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf btn-animated"
										:delay="{ show: 0, hide: 0 }"
									>
										<div class="counter">
											<span class="text">{{ treeData.company_people }}</span>
										</div>

										<template slot="popover">
											<div class="simple-text">{{ $tc("tree-chart.employees", treeData.company_people) }}</div>
										</template>
									</v-popover>
									<div v-if="$auth.user().rights.admins_crud" class="dropdown menu-animated simple-box">
										<div
											class="open-menu"
											id="dropdownCompany"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<editDotsIcon />
										</div>
										<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownCompany">
											<div class="menu-items">
												<div class="btn-animated add-action" @click="$router.push({ name: 'user-create' })">
													<v-popover
														:disabled="$resize && $mq.below(780)"
														offset="2"
														trigger="hover"
														placement="bottom"
														popoverBaseClass="popover-tbf btn-animated"
														:delay="{ show: 0, hide: 0 }"
													>
														<icon-user />

														<template slot="popover">
															<div class="simple-text">{{ $t("tree-chart.add_user") }}</div>
														</template>
													</v-popover>
												</div>
												<div class="btn-animated edit-action" @click="showModal('department', { from: 'chart' })">
													<v-popover
														:disabled="$resize && $mq.below(780)"
														offset="2"
														trigger="hover"
														placement="bottom"
														popoverBaseClass="popover-tbf btn-animated"
														:delay="{ show: 0, hide: 0 }"
													>
														<icon-department />

														<template slot="popover">
															<div class="simple-text">{{ $t("tree-chart.add_department") }}</div>
														</template>
													</v-popover>
												</div>
												<div
													class="btn-animated edit-action"
													@click="
														showModal('company', {
															id: treeData.id,
															from: 'chart',
															name: treeData.company_name,
															avatar: treeData.company_avatar,
															company_responsible: treeData.company_responsible,
														})
													"
												>
													<v-popover
														:disabled="$resize && $mq.below(780)"
														offset="2"
														trigger="hover"
														placement="bottom"
														popoverBaseClass="popover-tbf btn-animated"
														:delay="{ show: 0, hide: 0 }"
													>
														<icon-edit />

														<template slot="popover">
															<div class="simple-text">{{ $t("tree-chart.edit_company") }}</div>
														</template>
													</v-popover>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="title-company">
								<div class="avatar">
									<img
										:src="
											treeData.company_avatar.lastIndexOf('gravatar') >= 0
												? treeData.company_avatar
												: `/build${treeData.company_avatar.substring(
														treeData.company_avatar.lastIndexOf('.ro') + 3
												  )}`
										"
										class="avatar-user"
										v-if="treeData.company_avatar"
									/>
									<div class="user-circle" v-else>
										<icon-user />
									</div>
								</div>
								<div class="data">
									<div class="name">{{ treeData.company_name }}</div>
								</div>
							</div>
						</div>
						<div
							class="content-box entrepeneur"
							:class="{
								compact: densityType == 'compact',
								detailed: densityType == 'detailed',
								'with-color': treeData.color_code,
							}"
							:style="`--color: ${treeData.color_code};`"
							v-if="treeData.company_responsible && densityType != 'structural' && densityType != 'functional'"
						>
							<div
								v-if="
									treeData.company_responsible.date_of_birth &&
										moment(treeData.company_responsible.date_of_birth).format('DD-MM') == moment().format('DD-MM')
								"
								class="birthdayIcons responsible"
							>
								<v-popover
									offset="5"
									trigger="hover"
									placement="bottom"
									popoverBaseClass="popover-tbf"
									class="label"
									:delay="{ show: 100, hide: 0 }"
								>
									<img src="/build/images/ConeConfetti.png" class="icon" />
									<div slot="popover">
										<div class="simple-text" v-if="$auth.user().id == treeData.company_responsible.id">
											{{ $t("show_user.birthday.toYou") }}
										</div>
										<div class="simple-text" v-else>{{ $t("show_user.birthday.toHim") }}</div>
									</div>
								</v-popover>
							</div>
							<div class="line"></div>
							<div class="header-box">
								<div class="box-type" v-bind:class="treeData.company_responsible.free_day ? 'employee-gone' : ''">
									{{
										treeData.company_responsible.free_day
											? $t("tree-chart.employee_gone")
											: $t("tree-chart.responsible")
									}}
								</div>

								<div
									v-if="promises.ready"
									class="promise-status"
									v-bind:class="treeData.company_responsible.free_day ? 'employee-gone' : ''"
								>
									<v-popover
										:disabled="$resize && $mq.below(780)"
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf"
										:delay="{ show: 200, hide: 0 }"
									>
										<div
											class="circle-status"
											v-if="promises.users[treeData.company_responsible.id]"
											:class="{
												red: ['not_completed', 'not_set'].includes(
													promises.users[treeData.company_responsible.id].status
												),
												yellow: ['pending'].includes(promises.users[treeData.company_responsible.id].status),
												green: ['completed'].includes(promises.users[treeData.company_responsible.id].status),
												grey: ['no_key_result', 'not_working'].includes(
													promises.users[treeData.company_responsible.id].status
												),
											}"
										></div>
										<div v-else class="circle-status grey"></div>

										<template slot="popover">
											<div class="simple-text">
												{{
													promises.users[treeData.company_responsible.id]
														? $t(
																`promise_objectives.status.${
																	promises.users[treeData.company_responsible.id].status
																}`
														  )
														: $t(`promise_objectives.status.not_working`)
												}}
											</div>
										</template>
									</v-popover>
								</div>
							</div>

							<div
								class="user-data"
								:class="{
									'employee-gone': treeData.company_responsible.free_day,
									compact: densityType == 'compact',
									detailed: densityType == 'detailed',
								}"
							>
								<div class="avatar">
									<img
										:src="
											treeData.company_responsible.avatar.lastIndexOf('gravatar') >= 0
												? treeData.company_responsible.avatar
												: `/build${treeData.company_responsible.avatar.substring(
														treeData.company_responsible.avatar.lastIndexOf('.ro') + 3
												  )}`
										"
										class="avatar-user"
										v-if="treeData.company_responsible.avatar"
									/>
									<div class="user-circle" v-else>
										<icon-user />
									</div>
								</div>
								<div class="data" :class="{ compact: densityType == 'compact', detailed: densityType == 'detailed' }">
									<v-popover
										:disabled="$resize && $mq.below(780)"
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf"
										:delay="{ show: 200, hide: 0 }"
									>
										<div
											class="name"
											:class="{
												link: treeData.company_responsible.rights ? treeData.company_responsible.rights.view : '',
											}"
											@click="
												treeData.company_responsible.rights.view
													? showModal('sidebar_org', { slug: treeData.company_responsible.slug })
													: ''
											"
										>
											{{ treeData.company_responsible.first_name + " " + treeData.company_responsible.last_name }}
										</div>
										<div class="roles" v-if="treeData.company_responsible.job_function">
											{{ treeData.company_responsible.job_function }}
										</div>
										<div class="roles" v-else>
											<span v-for="(role, index) in treeData.company_responsible.roles"
												><span>{{ role.name }}</span
												><span v-if="index + 1 < treeData.company_responsible.roles.length">, </span></span
											>
										</div>

										<template slot="popover">
											<div class="title">
												{{ treeData.company_responsible.first_name + " " + treeData.company_responsible.last_name }}
											</div>
											<div class="info-text">
												<span v-for="(role, index) in treeData.company_responsible.roles"
													><span>{{ role.name }}</span
													><span v-if="index + 1 < treeData.company_responsible.roles.length">, </span></span
												>
											</div>
										</template>
									</v-popover>
								</div>
							</div>

							<div class="level2Data" v-if="densityType == 'compact'">
								<div class="data" v-if="treeData.company_responsible.email">
									<div class="item medium">
										<icon-email class="icon" /><a :href="`mailto:${treeData.email}`" class="text email">{{
											treeData.company_responsible.email
										}}</a>
									</div>
								</div>
								<div class="data" v-if="treeData.company_responsible.phone">
									<div class="item">
										<icon-phone class="icon" />
										<div class="text">{{ treeData.company_responsible.phone }}</div>
									</div>
								</div>
							</div>
							<div class="level2Data" v-if="densityType == 'detailed'">
								<div class="data wrap">
									<div class="item big" v-if="treeData.company_responsible.email">
										<icon-email class="icon" /><a
											:href="`mailto:${treeData.company_responsible.email}`"
											class="text email"
											>{{ treeData.company_responsible.email }}</a
										>
									</div>
									<div class="item" v-if="treeData.company_responsible.phone">
										<icon-phone class="icon" />
										<div class="text">{{ treeData.company_responsible.phone }}</div>
									</div>
									<div class="item" v-if="treeData.company_responsible.workstation">
										<icon-building class="icon" />
										<div class="text">{{ treeData.company_responsible.workstation.name }}</div>
									</div>
									<div class="item" v-if="treeData.company_responsible.date_of_birth">
										<icon-cake class="icon" />
										<div class="text">{{ treeData.company_responsible.date_of_birth | moment("DD MMM YYYY") }}</div>
									</div>
									<div class="item" v-if="treeData.company_responsible.date_of_employment">
										<icon-hourglass class="icon" />
										<div class="text">{{ workingSince(treeData.company_responsible.date_of_employment) }}</div>
									</div>
								</div>
								<div
									class="data wrap skills"
									v-if="treeData.company_responsible.skills && treeData.company_responsible.skills.length"
								>
									<icon-skills class="icon skills" />
									<div class="skill" v-for="skill in treeData.company_responsible.skills.slice(0, 5)">
										{{ skill.name }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<!-- CHILD LIGHT GREY -->
					<div
						class="box-tbf department-box"
						:class="{ 'compact-department': densityType == 'compact', 'detailed-department': densityType == 'detailed' }"
						v-if="treeData.type == 'department'"
					>
						<div
							class="content-box"
							:class="{
								'with-responsible': treeData.responsible && densityType != 'structural',
								'with-color': treeData.color_code,
							}"
							:style="`--color: ${treeData.color_code};`"
						>
							<div class="header-box">
								<div class="box-type">
									{{ $t("tree-chart.department") }}
								</div>
								<div class="box-actions">
									<v-popover
										class="counter"
										:disabled="$resize && $mq.below(780)"
										offset="2"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf btn-animated"
										:delay="{ show: 0, hide: 0 }"
									>
										<div class="counter">
											<span class="text">{{ treeData.people }}</span>
										</div>

										<template slot="popover">
											<div class="simple-text">{{ $tc("tree-chart.employees", treeData.people) }}</div>
										</template>
									</v-popover>
									<div v-if="$auth.user().rights.admins_crud" class="dropdown menu-animated simple-box">
										<div
											class="open-menu"
											:id="'dropdownProcedure' + treeData.id"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											<editDotsIcon />
										</div>
										<div class="dropdown-menu dropdown-menu-right" :aria-labelledby="'dropdownProcedure' + treeData.id">
											<div class="menu-items">
												<div
													class="btn-animated add-action"
													@click="
														showModal('user', {
															department_id: treeData.id,
															department_slug: treeData.slug,
															from: 'chart',
														})
													"
												>
													<v-popover
														:disabled="$resize && $mq.below(780)"
														offset="2"
														trigger="hover"
														placement="bottom"
														popoverBaseClass="popover-tbf btn-animated"
														:delay="{ show: 0, hide: 0 }"
													>
														<icon-user />

														<template slot="popover">
															<div class="simple-text">{{ $t("tree-chart.add_user") }}</div>
														</template>
													</v-popover>
												</div>
												<div
													class="btn-animated edit-action"
													@click="
														showModal('department', { id: treeData.id, parent_id: treeData.id, from: 'chart' })
													"
												>
													<v-popover
														:disabled="$resize && $mq.below(780)"
														offset="2"
														trigger="hover"
														placement="bottom"
														popoverBaseClass="popover-tbf btn-animated"
														:delay="{ show: 0, hide: 0 }"
													>
														<icon-department />

														<template slot="popover">
															<div class="simple-text">{{ $t("tree-chart.add_department") }}</div>
														</template>
													</v-popover>
												</div>
												<div
													class="btn-animated edit-action"
													@click="
														showModal('department', {
															id: treeData.id,
															parent_id: treeData.department_id,
															from: 'chart',
															edited: true,
															slug: treeData.slug,
														})
													"
												>
													<v-popover
														:disabled="$resize && $mq.below(780)"
														offset="2"
														trigger="hover"
														placement="bottom"
														popoverBaseClass="popover-tbf btn-animated"
														:delay="{ show: 0, hide: 0 }"
													>
														<icon-edit />

														<template slot="popover">
															<div class="simple-text">{{ $t("tree-chart.edit_department") }}</div>
														</template>
													</v-popover>
												</div>
												<div
													class="btn-animated delete-action"
													@click="
														showModal('delete', {
															type: 'department',
															model: { slug: treeData.slug },
															from: 'chart',
															parent: treeData.children
																? treeData.children.find((el) => el.type == 'department')
																	? true
																	: false
																: '',
														})
													"
												>
													<v-popover
														:disabled="$resize && $mq.below(780)"
														offset="2"
														trigger="hover"
														placement="bottom"
														popoverBaseClass="popover-tbf btn-animated"
														:delay="{ show: 0, hide: 0 }"
													>
														<icon-trash />

														<template slot="popover">
															<div class="simple-text">{{ $t("general.delete") }}</div>
														</template>
													</v-popover>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="title-department">
								<v-popover
									offset="2"
									trigger="hover"
									placement="bottom"
									popoverBaseClass="popover-tbf"
									:disabled="!treeData.description || ($resize && $mq.below(780))"
									:delay="{ show: 200, hide: 0 }"
								>
									<span class="text" @click="goToDepartment(treeData.slug)">{{ treeData.name }}</span>

									<template slot="popover">
										<div class="simple-text">{{ treeData.description }}</div>
									</template>
								</v-popover>
							</div>
						</div>
						<div
							class="content-box department-responsible"
							:class="{
								compact: densityType == 'compact',
								detailed: densityType == 'detailed',
								'with-color': treeData.color_code,
							}"
							:style="`--color: ${treeData.color_code};`"
							v-if="treeData.responsible && densityType != 'structural'"
						>
							<div
								v-if="
									treeData.responsible.date_of_birth &&
										moment(treeData.responsible.date_of_birth).format('DD-MM') == moment().format('DD-MM')
								"
								class="birthdayIcons responsible"
							>
								<v-popover
									offset="5"
									trigger="hover"
									placement="bottom"
									popoverBaseClass="popover-tbf"
									class="label"
									:delay="{ show: 100, hide: 0 }"
								>
									<img src="/build/images/ConeConfetti.png" class="icon" />
									<div slot="popover">
										<div class="simple-text" v-if="$auth.user().id == treeData.responsible.id">
											{{ $t("show_user.birthday.toYou") }}
										</div>
										<div class="simple-text" v-else>{{ $t("show_user.birthday.toHim") }}</div>
									</div>
								</v-popover>
							</div>
							<div class="line"></div>
							<div class="header-box">
								<div class="box-type" v-bind:class="treeData.responsible.free_day ? 'employee-gone' : ''">
									{{ treeData.responsible.free_day ? $t("tree-chart.employee_gone") : $t("tree-chart.responsible") }}
								</div>

								<div
									v-if="promises.ready"
									class="promise-status"
									v-bind:class="treeData.responsible.free_day ? 'employee-gone' : ''"
								>
									<v-popover
										:disabled="$resize && $mq.below(780)"
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf"
										:delay="{ show: 200, hide: 0 }"
									>
										<div
											class="circle-status"
											v-if="promises.users[treeData.responsible.id]"
											:class="{
												red: ['not_completed', 'not_set'].includes(promises.users[treeData.responsible.id].status),
												yellow: ['pending'].includes(promises.users[treeData.responsible.id].status),
												green: ['completed'].includes(promises.users[treeData.responsible.id].status),
												grey: ['no_key_result', 'not_working'].includes(
													promises.users[treeData.responsible.id].status
												),
											}"
										></div>
										<div v-else class="circle-status grey"></div>

										<template slot="popover">
											<div class="simple-text">
												{{
													promises.users[treeData.responsible.id]
														? $t(`promise_objectives.status.${promises.users[treeData.responsible.id].status}`)
														: $t(`promise_objectives.status.not_working`)
												}}
											</div>
										</template>
									</v-popover>
								</div>
							</div>

							<div
								class="user-data"
								:class="{
									'employee-gone': treeData.responsible.free_day,
									compact: densityType == 'compact',
									detailed: densityType == 'detailed',
								}"
							>
								<div class="avatar">
									<img
										:src="
											treeData.responsible.avatar.lastIndexOf('gravatar') >= 0
												? treeData.responsible.avatar
												: `/build${treeData.responsible.avatar.substring(
														treeData.responsible.avatar.lastIndexOf('.ro') + 3
												  )}`
										"
										class="avatar-user"
										v-if="treeData.responsible.avatar"
									/>
									<div class="user-circle" v-else>
										<icon-user />
									</div>
								</div>
								<div class="data" :class="{ compact: densityType == 'compact', detailed: densityType == 'detailed' }">
									<v-popover
										:disabled="$resize && $mq.below(780)"
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf"
										:delay="{ show: 200, hide: 0 }"
									>
										<div
											class="name"
											:class="{ link: treeData.responsible.rights ? treeData.responsible.rights.view : '' }"
											@click="
												treeData.responsible.rights.view
													? showModal('sidebar_org', { slug: treeData.responsible.slug })
													: ''
											"
										>
											{{ treeData.responsible.first_name + " " + treeData.responsible.last_name }}
										</div>
										<div class="roles" v-if="treeData.responsible.job_function">
											{{ treeData.responsible.job_function }}
										</div>
										<div class="roles" v-else>
											<span v-for="(role, index) in treeData.responsible.roles"
												><span>{{ role.name }}</span
												><span v-if="index + 1 < treeData.responsible.roles.length">, </span></span
											>
										</div>

										<template slot="popover">
											<div class="title">
												{{ treeData.responsible.first_name + " " + treeData.responsible.last_name }}
											</div>
											<div class="info-text">
												<span v-for="(role, index) in treeData.responsible.roles"
													><span>{{ role.name }}</span
													><span v-if="index + 1 < treeData.responsible.roles.length">, </span></span
												>
											</div>
										</template>
									</v-popover>
								</div>
							</div>

							<div class="level2Data" v-if="densityType == 'compact'">
								<div class="data" v-if="treeData.responsible.email">
									<div class="item medium">
										<icon-email class="icon" /><a :href="`mailto:${treeData.email}`" class="text email">{{
											treeData.responsible.email
										}}</a>
									</div>
								</div>
								<div class="data" v-if="treeData.responsible.phone">
									<div class="item">
										<icon-phone class="icon" />
										<div class="text">{{ treeData.responsible.phone }}</div>
									</div>
								</div>
							</div>
							<div class="level2Data" v-if="densityType == 'detailed'">
								<div class="data wrap">
									<div class="item big" v-if="treeData.responsible.email">
										<icon-email class="icon" /><a :href="`mailto:${treeData.responsible.email}`" class="text email">{{
											treeData.responsible.email
										}}</a>
									</div>
									<div class="item" v-if="treeData.responsible.phone">
										<icon-phone class="icon" />
										<div class="text">{{ treeData.responsible.phone }}</div>
									</div>
									<div class="item" v-if="treeData.responsible.workstation">
										<icon-building class="icon" />
										<div class="text">{{ treeData.responsible.workstation.name }}</div>
									</div>
									<div class="item" v-if="treeData.responsible.date_of_birth">
										<icon-cake class="icon" />
										<div class="text">{{ treeData.responsible.date_of_birth | moment("DD MMM YYYY") }}</div>
									</div>
									<div class="item" v-if="treeData.responsible.date_of_employment">
										<icon-hourglass class="icon" />
										<div class="text">{{ workingSince(treeData.responsible.date_of_employment) }}</div>
									</div>
								</div>
								<div class="data wrap skills" v-if="treeData.responsible.skills && treeData.responsible.skills.length">
									<icon-skills class="icon skills" />
									<div class="skill" v-for="skill in treeData.responsible.skills.slice(0, 5)">{{ skill.name }}</div>
								</div>
							</div>
						</div>
					</div>

					<div
						class="box-tbf"
						:class="[
							treeData.free_day ? 'employee-gone' : 'user-box',
							{ compact: densityType == 'compact', detailed: densityType == 'detailed', 'with-color': treeData.color_code },
						]"
						:style="`--color: ${treeData.color_code};`"
						v-if="treeData.type == 'user'"
					>
						<div class="line"></div>
						<div class="content-box">
							<div
								class="birthdayIcons"
								v-if="treeData.date_of_birth && moment(treeData.date_of_birth).format('DD-MM') == moment().format('DD-MM')"
							>
								<v-popover
									offset="5"
									trigger="hover"
									placement="bottom"
									popoverBaseClass="popover-tbf"
									class="label"
									:delay="{ show: 100, hide: 0 }"
								>
									<img src="/build/images/ConeConfetti.png" class="icon" />
									<div slot="popover">
										<div class="simple-text" v-if="$auth.user().id == treeData.id">
											{{ $t("show_user.birthday.toYou") }}
										</div>
										<div class="simple-text" v-else>{{ $t("show_user.birthday.toHim") }}</div>
									</div>
								</v-popover>
							</div>
							<div class="header-box">
								<div class="box-type" v-bind:class="treeData.free_day ? 'employee-gone' : ''">
									{{ treeData.free_day ? $t("tree-chart.employee_gone") : $t("tree-chart.employee") }}
								</div>

								<div v-if="promises.ready" class="promise-status" v-bind:class="treeData.free_day ? 'employee-gone' : ''">
									<v-popover
										:disabled="$resize && $mq.below(780)"
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf"
										:delay="{ show: 200, hide: 0 }"
									>
										<div
											class="circle-status"
											v-if="promises.users[treeData.id]"
											:class="{
												red: ['not_completed', 'not_set'].includes(promises.users[treeData.id].status),
												yellow: ['pending'].includes(promises.users[treeData.id].status),
												green: ['completed'].includes(promises.users[treeData.id].status),
												grey: ['no_key_result', 'not_working'].includes(promises.users[treeData.id].status),
											}"
										></div>
										<div v-else class="circle-status grey"></div>

										<template slot="popover">
											<div class="simple-text">
												{{
													promises.users[treeData.id]
														? $t(`promise_objectives.status.${promises.users[treeData.id].status}`)
														: $t(`promise_objectives.status.not_working`)
												}}
											</div>
										</template>
									</v-popover>
								</div>
							</div>

							<div
								class="user-data"
								:class="{
									'employee-gone': treeData.free_day,
									compact: densityType == 'compact',
									detailed: densityType == 'detailed',
								}"
							>
								<div class="avatar">
									<img
										:src="
											treeData.avatar.lastIndexOf('gravatar') >= 0
												? treeData.avatar
												: `/build${treeData.avatar.substring(treeData.avatar.lastIndexOf('.ro') + 3)}`
										"
										class="avatar-user"
										v-if="treeData.avatar"
									/>
									<div class="user-circle" v-else>
										<icon-user />
									</div>
								</div>
								<div class="data" :class="{ compact: densityType == 'compact', detailed: densityType == 'detailed' }">
									<v-popover
										:disabled="$resize && $mq.below(780)"
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf"
										:delay="{ show: 200, hide: 0 }"
									>
										<div
											class="name"
											:class="{ link: treeData.rights ? treeData.rights.view : '' }"
											@click="treeData.rights.view ? showModal('sidebar_org', { slug: treeData.slug }) : ''"
										>
											{{ treeData.first_name + " " + treeData.last_name }}
										</div>
										<div class="roles" v-if="treeData.job_function">{{ treeData.job_function }}</div>
										<div class="roles" v-else>
											<span v-for="(role, index) in treeData.roles"
												><span>{{ role.name }}</span
												><span v-if="index + 1 < treeData.roles.length">, </span></span
											>
										</div>
										<template slot="popover">
											<div class="title">{{ treeData.first_name + " " + treeData.last_name }}</div>
											<div class="info-text">
												<span v-for="(role, index) in treeData.roles"
													><span>{{ role.name }}</span
													><span v-if="index + 1 < treeData.roles.length">, </span></span
												>
											</div>
										</template>
									</v-popover>
								</div>
							</div>
							<div class="level2Data" v-if="densityType == 'compact'">
								<div class="data" v-if="treeData.email">
									<div class="item medium">
										<icon-email class="icon" /><a :href="`mailto:${treeData.email}`" class="text email">{{
											treeData.email
										}}</a>
									</div>
								</div>
								<div class="data" v-if="treeData.phone">
									<div class="item">
										<icon-phone class="icon" />
										<div class="text">{{ treeData.phone }}</div>
									</div>
								</div>
							</div>
							<div class="level2Data" v-if="densityType == 'detailed'">
								<div class="data wrap">
									<div class="item big" v-if="treeData.email">
										<icon-email class="icon" /><a :href="`mailto:${treeData.email}`" class="text email">{{
											treeData.email
										}}</a>
									</div>
									<div class="item" v-if="treeData.phone">
										<icon-phone class="icon" />
										<div class="text">{{ treeData.phone }}</div>
									</div>
									<div class="item" v-if="treeData.workstation">
										<icon-building class="icon" />
										<div class="text">{{ treeData.workstation.name }}</div>
									</div>
									<div class="item" v-if="treeData.date_of_birth">
										<icon-cake class="icon" />
										<div class="text">{{ treeData.date_of_birth | moment("DD MMM YYYY") }}</div>
									</div>
									<div class="item" v-if="treeData.date_of_employment">
										<icon-hourglass class="icon" />
										<div class="text">{{ workingSince(treeData.date_of_employment) }}</div>
									</div>
								</div>
								<div class="data wrap skills" v-if="treeData.skills && treeData.skills.length">
									<icon-skills class="icon skills" />
									<div class="skill" v-for="skill in treeData.skills.slice(0, 5)">{{ skill.name }}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="box-tbf user-box" v-if="treeData.type == 'function'">
						<div class="line"></div>
						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{ $t("tree-chart.function") }}
								</div>
								<div class="box-actions">
									<v-popover
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf"
										:delay="{ show: 200, hide: 0 }"
									>
										<div class="users-avatars">
											<div class="image" v-for="user in treeData.users.slice(0, 3)">
												<img
													:src="
														user.avatar.lastIndexOf('gravatar') >= 0
															? user.avatar
															: `/build${user.avatar.substring(user.avatar.lastIndexOf('.ro') + 3)}`
													"
												/>
											</div>
											<div class="image plus-icon" v-if="treeData.users.length > 3">
												<icon-plus />
												<span class="numer">{{ treeData.users.length - 3 }}</span>
											</div>
										</div>
										<template slot="popover">
											<div class="simple-text">
												{{
													treeData.users
														.map((el) => {
															return el.name;
														})
														.join(", ")
												}}
											</div>
										</template>
									</v-popover>
								</div>
							</div>

							<div class="user-data">
								<div class="data">
									<div class="name" :class="[!treeData.function ? 'no-function' : '']">
										{{ treeData.function ? treeData.function : $t("tree-chart.no-function") }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="box-tbf vacant-job-box" v-if="treeData.type == 'vacant_job'">
						<div class="content-box">
							<div class="header-box">
								<div class="box-type">
									{{ $t("tree-chart.vacant_job") }}
								</div>
							</div>

							<div class="user-data">
								<div class="data">
									<v-popover
										:disabled="$resize && $mq.below(780)"
										offset="5"
										trigger="hover"
										placement="bottom"
										popoverBaseClass="popover-tbf"
										:delay="{ show: 200, hide: 0 }"
									>
										<div class="name link" @click="showModal('sidebar_vacant_job', { slug: treeData.slug })">
											{{ treeData.name }}
										</div>
										<div class="roles">
											<span v-for="(role, index) in treeData.roles"
												><span>{{ role.name }}</span
												><span v-if="index + 1 < treeData.roles.length">, </span></span
											>
										</div>

										<template slot="popover">
											<div class="title">{{ treeData.name }}</div>
											<div class="info-text">{{ treeData.description ? treeData.description : "" }}</div>
										</template>
									</v-popover>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div
					class="extend-handle-tbf"
					:class="{ open: !parentOpen }"
					@click="toggleExtend(treeData)"
					v-if="treeData.children && treeData.children.length > 0 && checkChildrenFilters(treeData.children)"
				></div>
			</td>
		</tr>
		<tr v-if="treeData.children && treeData.extend && checkChildrenFilters(treeData.children)" class="children-row-wrapper">
			<td
				v-for="(children, index) in treeData.children"
				:key="index"
				colspan="2"
				class="childLevel"
				v-if="checkUserFilters(children)"
				:class="[
					{ 'single-user': treeData.type == 'user' && children.type == 'user' },
					{ department: children.type == 'department' },
					treeData.type == 'department' && children.type == 'user' ? `color${treeData.id}` : '',
				]"
			>
				<TreeChart
					:json="children"
					:zoomPage="zoomPage"
					:filters="filters"
					@click-node="$emit('click-node', $event)"
					:promises="promises"
					:densityType="densityType"
				/>
			</td>
		</tr>
	</table>
</template>

<script>
import EditDotsIcon from "../../Icons/EditDots";
import IconUser from "../../Icons/User";
import IconDepartment from "../../Icons/Department";
import IconTrash from "../../Icons/Trash";
import IconEdit from "../../Icons/Edit";
import IconEmail from "../../Icons/Email";
import IconPhone from "../../Icons/Phone";
import IconCake from "../../Icons/BirthdayCake";
import IconBuilding from "../../Icons/Building";
import IconSkills from "../../Icons/Skill";
import IconPipe from "../../Icons/Pipe";
import IconHourglass from "../../Icons/Hourglass";
import IconPlus from "../../Icons/Plus";

export default {
	data() {
		return {
			treeData: {},
			parentOpen: false,
			filtersUser: {},
		};
	},
	props: {
		json: Object,
		zoomPage: Number,
		filters: Object,
		promises: Object,
		densityType: String,
	},
	name: "TreeChart",
	components: {
		EditDotsIcon,
		IconUser,
		IconDepartment,
		IconTrash,
		IconEdit,
		IconEmail,
		IconPhone,
		IconCake,
		IconBuilding,
		IconSkills,
		IconPipe,
		IconHourglass,
		IconPlus,
	},
	watch: {
		json: {
			handler: function(Props) {
				let extendKey = function(jsonData) {
					var jsonExtend = localStorage.getItem("jsonExtend") ? JSON.parse(localStorage.getItem("jsonExtend")) : [];

					if (jsonExtend.find((el) => el.id == jsonData.id)) {
						jsonData.extend = jsonExtend.find((el) => el.id == jsonData.id).extend;
					} else {
						jsonData.extend = true;
						jsonExtend.push({ id: jsonData.id, extend: jsonData.extend });
					}
					localStorage.setItem("jsonExtend", JSON.stringify(jsonExtend));

					return jsonData;
				};
				if (Props) {
					if (typeof Props === "object") {
						this.treeData = extendKey(Props);
					}
				}
			},
			immediate: true,
		},
		filters: {
			immediate: true,
			deep: true,
			handler(val) {
				this.filtersUser = val;
			},
		},
	},
	mounted() {
		this.$root.$on("toggleExtendAll", (valueExtend) => {
			this.treeData = extendKey2(this.treeData, valueExtend);
			this.$forceUpdate();

			var noCountExntend = 0;
			localStorage.setItem("numberCount", noCountExntend ? (noCountExntend = parseInt(noCountExntend) + 1) : 1);
		});

		function extendKey2(jsonData, extendValue) {
			var jsonExtend = localStorage.getItem("jsonExtend") ? JSON.parse(localStorage.getItem("jsonExtend")) : [];

			jsonData.extend = extendValue;
			if (jsonExtend.find((el) => el.id == jsonData.id)) {
				jsonExtend.find((el) => el.id == jsonData.id).extend = jsonData.extend;
			} else {
				jsonExtend.push({ id: jsonData.id, extend: jsonData.extend });
			}
			localStorage.setItem("jsonExtend", JSON.stringify(jsonExtend));

			return jsonData;
		}

		var noCount = localStorage.getItem("numberCount");
		localStorage.setItem("numberCount", noCount ? (noCount = parseInt(noCount) + 1) : 1);

		if (noCount == localStorage.getItem("numberCountExtend")) {
			this.centerOrg();
		}
	},
	methods: {
		goToDepartment(slug) {
			this.$router.push({ name: "department", params: { slug: slug }, query: this.$route.query });
		},
		toggleExtend: function(treeData) {
			var jsonExtend = localStorage.getItem("jsonExtend") ? JSON.parse(localStorage.getItem("jsonExtend")) : [];
			treeData.extend = !treeData.extend;
			jsonExtend.find((el) => el.id == treeData.id).extend = treeData.extend;
			localStorage.setItem("jsonExtend", JSON.stringify(jsonExtend));

			this.parentOpen = !this.parentOpen;
			this.$forceUpdate();
		},
		showModal(type, data = false) {
			this.$root.$emit("open_modal_full_template", type, data);
		},
		centerOrg() {
			var calcWidth =
				$(".scrollable-chart .center-box .parentLevel")
					.first()
					.children(".node")
					.position().left *
					this.zoomPage -
				$(window).width() / 2 +
				($(".scrollable-chart .center-box .parentLevel")
					.first()
					.children(".node")
					.width() /
					2) *
					this.zoomPage +
				30;

			$(".scrollable-chart").animate(
				{
					scrollTop: $(".scrollable-chart").offset().top - $(window).height() / 2,
					scrollLeft: calcWidth,
				},
				300
			);
		},
		checkUserFilters(children) {
			if (children.type == "user" || children.type == "vacant_job") {
				//Check Filter Role
				var existRole = true;
				if (this.filtersUser.role.length) {
					existRole = false;
					children.roles.map((el) => {
						if (this.filtersUser.role.some((role) => role.id == el.id)) {
							existRole = true;
						}
					});
				}
				//Check Filter Workstation
				var existWorkstation = true;
				if (this.filtersUser.workstation.length) {
					existWorkstation = false;
					if (this.filtersUser.workstation.some((workstation) => workstation.id == children.workstation_id)) {
						existWorkstation = true;
					}
				}
				//Check Filter Work Schedule
				var existWorkSchedule = true;
				if (this.filtersUser.work_schedule.length) {
					existWorkSchedule = false;
					if (this.filtersUser.work_schedule.some((work_schedule) => work_schedule.id == children.work_schedule_id)) {
						existWorkSchedule = true;
					}
				}
				//Check Filter Skill
				var existSkill = true;
				if (this.filtersUser.skill.length) {
					existSkill = false;
					children.skills_ids.map((el) => {
						if (this.filtersUser.skill.some((skill) => skill.id == el)) {
							existSkill = true;
						}
					});
				}
				//Check Filter Language
				var existLanguage = true;
				if (this.filtersUser.language.length) {
					existLanguage = false;
					children.languages_ids.map((el) => {
						if (this.filtersUser.language.some((language) => language.id == el)) {
							existLanguage = true;
						}
					});
				}

				if (existRole && existWorkstation && existWorkSchedule && existSkill && existLanguage && this.densityType != "structural") {
					return true;
				} else {
					return false;
				}
			} else {
				return true;
			}
		},
		checkChildrenFilters(childrens) {
			var canView = false;

			childrens.every((el) => {
				canView = this.checkUserFilters(el);
			});

			return canView;
		},
		workingSince(date) {
			var a = moment();
			var b = moment(date);

			var years = a.diff(b, "year");
			b.add(years, "years");

			var months = a.diff(b, "months");
			b.add(months, "months");

			var days = a.diff(b, "days");

			if (years != 0) {
				return `${this.$tc("tree-chart.years", years)} ${this.$tc("tree-chart.months", months)}`;
			} else {
				return `${this.$tc("tree-chart.months", months)} ${this.$tc("tree-chart.days", days)}`;
			}
		},
	},
};
</script>
